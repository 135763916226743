import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import './styles.scss';
import { Fragment } from 'react';

export default function Why(props) {
  const { isMobile, title } = props;
  return (
    <div className='grey-container why'>
      <div className='heading'>
        <h1>
          Why <span>{title}?</span>
        </h1>
      </div>
      <Container>
        <Row>
          <Fade effect='top'>
            <h2 className='justify-text-center px-4'>
              Modern Godaam provides wholistic logistics solutions provides
              end-to-end supply chain. Wide variety of discounted domestic
              shipping for our valued clients with cash on delivery facilities,
              and many other value added services for a fraction of the cost.
            </h2>
          </Fade>
        </Row>
      </Container>
      <div className='mt-4 why-details'>
        <Row className={`mx-auto w-75 ${isMobile ? 'd-block mt-4' : ''}`}>
          <Col>
            <Row className='h-75 mx-auto' style={{ width: '90%' }}>
              <Image
                className='w-100 h-100'
                src={require('../../assets/images/why-4.png')}
              />
            </Row>
            <Row className='mt-4'>
              <p className='mx-auto text-center'>Technology Driven Systems</p>
            </Row>
          </Col>
          <Col>
            <Row className='h-75 mx-auto' style={{ width: '90%' }}>
              <Image
                className='w-100 h-100'
                src={require('../../assets/images/why-5.png')}
              />
            </Row>
            <Row className='mt-4'>
              <p className='mx-auto text-center'>Experienced Professionals</p>
            </Row>
          </Col>
          <Col>
            <Row className=' h-75 mx-auto' style={{ width: '90%' }}>
              <Image
                className='w-100 h-100'
                src={require('../../assets/images/why-6.png')}
              />
            </Row>
            <Row className='mt-4'>
              <p className='mx-auto text-center'>Zero Warehouse Shrinkage</p>
            </Row>
          </Col>
        </Row>
        <Row className={`mx-auto w-75 ${isMobile ? 'd-block' : ''}`}>
          <Col>
            <Row className='h-75 mx-auto' style={{ width: '90%' }}>
              <Image
                className='w-100 h-100'
                src={require('../../assets/images/why-1.png')}
              />
            </Row>
            <Row className='mt-4'>
              <p className='mx-auto text-center'>
                Flexible Storage & Warehousing Facility
              </p>
            </Row>
          </Col>
          <Col>
            <Row className='h-75 mx-auto' style={{ width: '90%' }}>
              <Image
                className='w-100 h-100'
                src={require('../../assets/images/why-3.png')}
              />
            </Row>
            <Row className='mt-4'>
              <p className='mx-auto text-center'>
                Reduce Cost & Increased Efficiency
              </p>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
